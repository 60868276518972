<template>
  <div class="grid p-fluid">
    <div class="col-12 md:col-12">
      <div class="card">
        <SurgeryFilter
            @filter-changed="onSubmitFilter"
            :filterCount="filterCountString"
        />
      </div>
    </div>
    <div class="col-12 md:col-5">
      <div class="card ">
        <div class="country-title"><Dropdown :options="HypoThresholds" v-model="selectedThreshold" optionLabel="name" @change="changeThreshold"></Dropdown></div>
        <div v-if="checkSpinner1" style="text-align: center; position: relative;margin:27.4%; height:100%" class="flex justify-content-center" >
          <ProgressSpinner id="Spinner1" style="position: relative; height:100%" />
        </div>
        <div v-else class="flex justify-content-center"  >
          <HypoChart
              id="hypoDurationId"
              :hypoDurations="hypoDurations"
              :hypoTicks="[5,10,15,30,45,60]"
              :hypoThreshold="hypoThreshold"
              :title="pieTitle"
              :withinLimitDesc="withinLimitDesc"
              :overLimitDesc="overLimitDesc"
              :showSpinner="showRotate"
          />
        </div>
      </div>
    </div>
    <div class="col-12 md:col-7">
      <div class="card ">
        <div class="country-title"><Dropdown :options="HypoThresholdRates" v-model="selectedThresholdRate" optionLabel="name" @change="changeThresholdRate"></Dropdown>
        </div>
        <div v-if="checkSpinner1" style="text-align: center;position: relative; margin:19.2%;height: 100%" class="flex justify-content-center" >
          <ProgressSpinner id="Spinner2" style="position: relative;height: 100%"/>
        </div>
        <div v-else class="flex justify-content-center">
          <HypoBySurgeryChart
              id="hypoBySurgeryChartId"
              :surgeryTypes="surgeryTypes"
              title="Rates of hypotension by surgery type < 65 mmHg"
              :iohPassCountBySurgeryType="iohPassCountBySurgeryType"
              :iohFailCountBySurgeryType="iohFailCountBySurgeryType"
              :failThreshold="hypoBySurgeryFailThreshold"
          />
        </div>
        <div class="flex justify-content-center">
        </div>
      </div>
    </div>
    <div class="col-12 md:col-5">
      <div class="card ">
        <h5>SLU Score</h5>
        <div v-if="checkSpinner1" style="text-align: center; position: relative;margin:27.4%; height:100%" class="flex justify-content-center" >
          <ProgressSpinner id="Spinner1" style="position: relative; height:100%" />
        </div>
        <div v-else class="flex justify-content-center"  >
          <SluChart
              :title="pieTitle"
              :sluScores="sluScores"
              :showSpinner="showRotate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SurgeryFilter from "../components/SurgeryFilter.vue";
import HypoChart from "../components/HypoChart.vue";
import SluChart from "../components/SluChart.vue";
import HypoBySurgeryChart from "../components/HypoBySurgeryChart.vue";
import {API} from "aws-amplify";
export default {
  name: "HypotensionAnalysis",

  data() {
    return {
      activeIndex:0,
      tabIndex: 1,
      hypoThreshold: 20,
      hypoBySurgeryFailThreshold: 15,
      tabIndexRate: 1,
      usAvgData: [],
      showUSAvgChecked: true,
      pieTitle: "Duration of IOH for Mean Arterial Pressure < 65mmHg",
      withinLimitDesc: "Cases with <15mins of IOH",
      overLimitDesc: "Cases with >15mins of IOH",
      surgeryTypes: [
        "All",
        "Not entered",
        "Cardiac",
        "Neuro",
        "General",
        "Urology",
        "Gynecology",
        "ENT",
        "Plastics",
        "Vascular",
        "Thoracic",
        "Orthopedics",
        "Other",
      ],
      multiselectValue: null,
      multiselectValues: [
        {name: 'Cardiac', code: 'CA'},
        {name: 'Neuro', code: 'NE'},
        {name: 'General', code: 'GE'},
        {name: 'Urology', code: 'UR'},
        {name: 'Gynecology', code: 'GY'},
        {name: 'ENT', code: 'EN'},
        {name: 'Plastics', code: 'PL'},
        {name: 'Vascular', code: 'VA'},
        {name: 'Thoracic', code: 'TH'},
        {name: 'Orthopedics', code: 'OT'},
        {name: 'Other', code: 'OR'}

      ],
      allSurgeryTableRows: [],
      visibleSurgeryTableRows: [],
      showLoading1:false,
      showLoading2:false,
      showRotate:false,
      localState:{},
      hypoBySurgeryChartId:'chartHypo55mmHg',
      hypoDurationId:'chartDuration55mmHg',
      HypoThresholds: [
        {name: 'Duration of IOH for Mean Arterial Pressure  < 55mmHg', code: 0},
        {name: 'Duration of IOH for Mean Arterial Pressure  < 65mmHg', code: 1},
        {name: 'Duration of IOH for Mean Arterial Pressure  < 75mmHg', code: 2}
      ],
      HypoThresholdRates: [
        {name: 'Rates of hypotension by surgery type for Mean Arterial Pressure  < 55mmHg', code: 0},
        {name: 'Rates of hypotension by surgery type for Mean Arterial Pressure  < 65mmHg', code: 1},
        {name: 'Rates of hypotension by surgery type for Mean Arterial Pressure  < 75mmHg', code: 2}
      ],
      selectedThreshold :{name: 'Duration of IOH for Mean Arterial Pressure  < 65mmHg', code: 1},
      selectedThresholdRate :{name: 'Rates of hypotension by surgery type for Mean Arterial Pressure  < 65mmHg', code: 1},
      customersTable: null,
      customersTable1: null,
      customersTable2: null,
      selectedVisitorYear: null,
      selectedRevenueMonth: null,
      selectedOrderYear: null,
      selectedCustomers1: null,
      selectedCustomerYear: null,
      carouselResponsiveOptions: [
        {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 3
        },
        {
          breakpoint: '768px',
          numVisible: 2,
          numScroll: 2
        },
        {
          breakpoint: '560px',
          numVisible: 1,
          numScroll: 1
        }
      ],

      countryChart: {
        labels: ['RUS', 'Other', 'IND', 'AUS', 'JPN', 'USA', 'CHN'],
        datasets: [
          {
            data: [30, 18, 36, 54, 61, 90, 72],
            backgroundColor: [
              '#0F8BFD',
              '#545C6B',
              '#EC4DBC',
              '#EEE500',
              '#FC6161',
              '#00D0DE',
              '#873EFE',
            ],
            hoverBackgroundColor: [
              '#0F8BFD',
              '#545C6B',
              '#EC4DBC',
              '#EEE500',
              '#FC6161',
              '#00D0DE',
              '#873EFE',
            ],
            borderColor: 'transparent',
            fill: true
          }
        ]
      },
      countryChartOptions: {
        responsive: true
      },
      buttonClassBlue:"flex-initial flex align-items-center justify-content-center bg-blue-500 font-bold text-white m-2 px-5 py-3 border-round",
      buttonClassGreen:"flex-initial flex align-items-center justify-content-center bg-green-500 font-bold text-white m-2 px-5 py-3 border-round",
      barData: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'My First dataset',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: [65, 59, 80, 81, 56, 55, 40]
          },
          {
            label: 'My Second dataset',
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            data: [28, 48, 40, 19, 86, 27, 90]
          }
        ]
      },
      arOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          }
        }
      },
      facilities : []
    }
  },
  customerService: null,
  created() {
    // this.customerService = new CustomerService();


  },
  mounted() {
    this.showLoading2 = true
    this.showLoading1 = true;
    API
        .get("TestAPI", "/surgery-sessions", null)
        .then(response => {

          this.allSurgeryTableRows = response;
          this.visibleSurgeryTableRows = this.allSurgeryTableRows;
          this.visibleSurgeryTableRows = this.$store.getters.getFilteredSurgeries(this.allSurgeryTableRows);
          this.facilities = [...new Set(this.visibleSurgeryTableRows.map(item => item.SGS_FacilityName))];
          this.$store.dispatch("addFacilityOptions",this.facilities.map(x=> {return { name:x , code:x };}));
          this.showLoading1 = false;
        })
        .catch(error => {
          console.log(error.response);
          this.showLoading1 = false;
        })
        .finally(()=>{
          this.showLoading1 = false;
        });


  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-primary', 'text-light']
      } else {
        return ['bg-light', 'text-info']
      }
    },
    getHypoDurations(iohthreshold) {
      return this.visibleSurgeryTableRows
          .map(row => {
            this.hypoDurationId ='chartDuration'+iohthreshold.toString()+'mmHg';
            if(iohthreshold===55) {
              return row.STS_IOH_Under55 / 60;
            }
            else if(iohthreshold===65) {
              return row.STS_IOH_Under65 / 60;
            }
            else if (iohthreshold===75) {
              return row.STS_IOH_Under75 / 60;
            }
            return 0;
          });
    },
    getIohPassCountBySurgeryType(iohthreshold){
      return this.surgeryTypes.map(surgeryType => {
        return this.visibleSurgeryTableRows.filter(row => {
          if(row.SPI_SurgeryType === surgeryType
              || (row.SPI_SurgeryType===null && surgeryType==="Not entered")
              || surgeryType == "All") {
            if (iohthreshold === 55) {
              return row.STS_IOH_Under55 / 60 <= 5;
            } else if (iohthreshold === 65) {
              return row.STS_IOH_Under65 / 60 <= 15;
            } else if (iohthreshold === 75) {
              return row.STS_IOH_Under75 / 60 <= 30;
            }
            else{
              return false;
            }

          }
          else {
            return false;
          }

        }).length
      })
    },
    getIohFailCountBySurgeryType(iohthreshold) {
      return this.surgeryTypes.map(surgeryType => {
        return this.visibleSurgeryTableRows.filter(row => {
          if(row.SPI_SurgeryType === surgeryType
              || (row.SPI_SurgeryType===null && surgeryType==="Not entered")
              || surgeryType == "All") {
            if (iohthreshold === 55) {
              return row.STS_IOH_Under55 / 60 > 5;
            } else if (iohthreshold === 65) {
              return row.STS_IOH_Under65 / 60 > 15;
            } else if (iohthreshold === 75) {
              return row.STS_IOH_Under75 / 60 > 30;
            }
            else{
              return false;
            }
          }
          else{
            return false;
          }
        }).length
      })
    },
    changeThreshold(){
      switch (this.selectedThreshold.code)
      {
        case 0:
          this.hypoThreshold = 10;
          break;
        case 1:
          this.hypoThreshold = 20;
          break;
        case 2:
          this.hypoThreshold = 45;
          break;
      }
      this.setTabIndex(this.selectedThreshold.code);
    },
    changeThresholdRate(){
      switch (this.selectedThresholdRate.code) {
        case 0:
          this.hypoBySurgeryFailThreshold = 5;
          break;
        case 1:
          this.hypoBySurgeryFailThreshold = 15;
          break;
        case 2:
          this.hypoBySurgeryFailThreshold = 30;
          break;
      }
      this.setRateTabIndex(this.selectedThresholdRate.code);
    },
    onSubmitFilter(args) {
      this.visibleSurgeryTableRows = this.allSurgeryTableRows;
      if(args)
      {
        this.$store.dispatch("updateFilter",args);

      }
      else
      {
        this.$store.dispatch("emptyFilter");
      }

      this.visibleSurgeryTableRows = this.$store.getters.getFilteredSurgeries(this.allSurgeryTableRows);

    },
    setTabIndex(tabId){
      this.tabIndex = tabId;
    },
    setRateTabIndex(tabId){
      this.tabIndexRate = tabId;
    },

  },
  components: {
    SurgeryFilter,
    HypoBySurgeryChart,
    HypoChart,
    SluChart
  },
  computed:{
    checkSpinner1(){
      return this.showLoading1?'Ok':'';
    },
    checkSpinner2(){
      return this.showLoading2?'Ok':'';
    },
    filterCountString() {
      return `Showing ${this.visibleSurgeryTableRows.length} of ${this.allSurgeryTableRows.length} cases`
    },
    sluScores() {
      return this.visibleSurgeryTableRows.map(row => {
        return row.STS_Normo_SLU
      })
    },
    hypoDurations() {
      if(this.tabIndex===0){

        return  this.getHypoDurations(55);
      }
      else if(this.tabIndex===1){
        return  this.getHypoDurations(65);
      }
      else if(this.tabIndex===2){
        return  this.getHypoDurations(75);
      }
      else {
        return [];
      }
    },
    iohPassCountBySurgeryType(){
      if(this.tabIndexRate===0){
        return  this.getIohPassCountBySurgeryType(55);
      }
      else if(this.tabIndexRate===1){
        return  this.getIohPassCountBySurgeryType(65);
      }
      else if(this.tabIndexRate===2){
        return  this.getIohPassCountBySurgeryType(75);
      }
      else {
        return [];
      }
    },
    iohFailCountBySurgeryType() {
      if(this.tabIndexRate===0){
        return  this.getIohFailCountBySurgeryType(55);
      }
      else if(this.tabIndexRate===1){
        return  this.getIohFailCountBySurgeryType(65);
      }
      else if(this.tabIndexRate===2){
        return  this.getIohFailCountBySurgeryType(75);
      }
      else {
        return [];
      }
    }
  },

}
</script>

<style lang="scss" scoped>
::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }

}
</style>

<template>
<!--  <apex-chart type="donut" :options="options" :series="series" height="100%"></apex-chart>-->
  <Chart type="doughnut"  id="doughnutChart" :data="chartData" :options="options" style="position: relative; width:71.3%" ></Chart>
</template>

<script>
import Chart from "primevue/chart";

export default {
  beforeCreate() {
    Chart.destroy;
  },
  props: {
    hypoDurations: Array,
    hypoTicks: Array,
    hypoThreshold: Number,
    title: String,
    overLimitDesc: String,
    withinLimitDesc: String,
  },
  data() {
    return {
    }
  },
  methods: {
    getColorForPercentage(pct) {
      for (var i = 1; i < this.percentColors.length - 1; i++) {
        if (pct < this.percentColors[i].pct) {
          break;
        }
      }
      var lower = this.percentColors[i - 1];
      var upper = this.percentColors[i];
      var range = upper.pct - lower.pct;
      var rangePct = (pct - lower.pct) / range;
      var pctLower = 1 - rangePct;
      var pctUpper = rangePct;
      var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
      };
      return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    },
  },
  computed: {
    series(){
      if (!this.hypoTicks || !this.hypoDurations ||
        this.hypoTicks.length === 0 || this.hypoDurations.length === 0) return []
      let result = [this.hypoDurations.filter(duration => duration <= this.hypoTicks[0]).length]
      for(let i=0; i < this.hypoTicks.length - 1; i++) {
          result.push(
              this.hypoDurations.filter(
                  duration => duration > this.hypoTicks[i] &&
                  duration <= this.hypoTicks[i+1]).length)
        }
      result.push(this.hypoDurations.filter(duration => duration > this.hypoTicks[this.hypoTicks.length-1]).length)
      return result
    },
    options() {
      let labels = [`< ${this.hypoTicks[0]} mins`]
      for (let i = 0; i < this.hypoTicks.length - 1; i++) {
        labels.push(
            `${this.hypoTicks[i]}mins - ${this.hypoTicks[i + 1]}mins`)
      }
      labels.push(`> ${this.hypoTicks[this.hypoTicks.length - 1]} mins`)
      let colors = this.hypoTicks.map(tick => {
        return this.getColorForPercentage(tick / this.hypoTicks[this.hypoTicks.length - 1])
      })
      colors.push(this.getColorForPercentage(1))
      return {
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context){
                var label = context.label,
                    currentValue = context.raw,
                    total = context.chart._metasets[context.datasetIndex].total;

                var percentage = parseFloat((currentValue/total*100).toFixed(1));

                return label + ": " +currentValue + ' cases (' + percentage + '%)';
              }
            }
          },
          legend: {
            position: 'right',
          }
        },
        title: {
          text: this.title,
          style: {
            fontSize:  '16px',
          }
        },
        labels: labels,
        colors: colors,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
              }
            }
          }
        },
        dataLabels: {
          enabled: false,
          formatter: function (val, opts) {
            if (opts.seriesIndex === 0) return `< ${this.hypoTicks[0]} mins`
            else if (opts.seriesIndex >= this.hypoTicks.length) return `> ${this.hypoTicks[this.hypoTicks.length-1]} mins`
            else return `${this.hypoTicks[opts.seriesIndex-1]}-${this.hypoTicks[opts.seriesIndex]}mins`
          }.bind(this),
          dropShadow: {
            enabled: false
          }
        }
      }
    },

    chartData() {
      if (!this.hypoTicks || !this.hypoDurations ||
          this.hypoTicks.length === 0 || this.hypoDurations.length === 0) return []
      let hpdata = [this.hypoDurations.filter(duration => duration <= this.hypoTicks[0]).length]
      for(let i=0; i < this.hypoTicks.length - 1; i++) {
        hpdata.push(
            this.hypoDurations.filter(
                duration => duration > this.hypoTicks[i] &&
                    duration <= this.hypoTicks[i+1]).length)
      }
      hpdata.push(this.hypoDurations.filter(duration => duration > this.hypoTicks[this.hypoTicks.length-1]).length)
      let labels = [`< ${this.hypoTicks[0]} mins`]
      for (let i = 0; i < this.hypoTicks.length - 1; i++) {
        labels.push(
            `${this.hypoTicks[i]}mins - ${this.hypoTicks[i + 1]}mins`)
      }
      labels.push(`> ${this.hypoTicks[this.hypoTicks.length - 1]} mins`)
      let colors = this.hypoTicks.map(tick => {
        return this.getColorForPercentage(tick / this.hypoTicks[this.hypoTicks.length - 1])
      })
      colors.push(this.getColorForPercentage(1))
      return {
        labels: labels,
        datasets: [
          {
            data: hpdata,
            backgroundColor: colors,
            hoverBackgroundColor: colors,
            borderColor: 'transparent',
            fill: true
          }
        ]
      }
    },
    percentColors() {
      const greenCutoff = this.hypoThreshold / 60.0
      return [
        {pct: 0.0, color: {r: 0x00, g: 0x94, b: 0x06}},
        {pct: greenCutoff, color: {r: 0xfa, g: 0xb7, b: 0x33}},
        {pct: 1.0, color: {r: 0xc2, g: 0x0f, b: 0x01}}]
    }
  },

};
</script>

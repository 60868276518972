<template>
<!--  <apex-chart id="apex-chart" type="bar" :options="chartOptions" :series="series" height="100%"></apex-chart>-->
  <Chart type="bar" id="hypoBarChart" :data="barData" :options="barOptions" style="position: relative; width:100%"  ></Chart>
</template>
<script>
import Chart from "primevue/chart";

export default {
  name: 'HypoBySurgeryChart',
  beforeCreate() {
    Chart.destroy;
  },
  data() {
    return {
      barOptions: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context){
                const currentValuePercent = context.raw
                const currentValueCount = context.datasetIndex ?
                    this.iohFailCountBySurgeryType[context.dataIndex] :
                    this.iohPassCountBySurgeryType[context.dataIndex]
                const label = context.dataset.label
                return `${label} : ${currentValueCount} cases (${currentValuePercent}%)`;
              }.bind(this)
            }
          },
          legend: {
          }
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            min: 0,
            max: 100,
            title: {
              display: true,
              text: "Percentage (%)"
            },
            stacked: true,
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      },
    }},
  props: {
    surgeryTypes: Array,
    title: String,
    showSpinner:Boolean,
    iohPassCountBySurgeryType: Array,
    iohFailCountBySurgeryType: Array,
    failThreshold: Number
  },
  computed: {
    barData() {
      return {
        labels: this.surgeryTypes,
        datasets: [
          {
            type:'bar',
            label: `< ${this.failThreshold} mins of IOH`,
            backgroundColor: '#009406',
            borderColor: '#009406',
            data:this.geCountPercentageValue(this.iohPassCountBySurgeryType,this.iohFailCountBySurgeryType)
          },
          {
            type:'bar',
            label: `≥ ${this.failThreshold} mins of IOH`,
            backgroundColor: '#c20d00',
            borderColor: '#c20d00',
            data: this.geCountPercentageValue(this.iohPassCountBySurgeryType,this.iohFailCountBySurgeryType,'fail')
          }
        ]
      }
    },

  },
  methods: {
    geCountPercentageValue(passValues,failValues,type='pass'){
      let arrayTot = [];
      let passCountValue = passValues;
      let failCountValue = failValues;
      if (passCountValue.length === 0 )
        passCountValue=0;
      if (failCountValue.length === 0 )

        failCountValue=0;
      let index = 0;
      if (type=='pass')
      {
        passCountValue.forEach(()=>{
          arrayTot.push(passCountValue[index]>0?(Math.round(passValues[index]/(passValues[index]+failValues[index])*100)):0)
          index++;
        });
      }
      else if (type=='fail') {
        passCountValue.forEach(()=>{
          arrayTot.push(failCountValue[index]>0?(Math.round(failCountValue[index]/(passValues[index]+failValues[index])*100)):0)
          index++;
        });
      }
      return arrayTot;
    },

  }
}
</script>

<template>
  <Chart type="doughnut"  id="doughnutChart" :data="chartData" :options="options" style="position: relative; width:71.3%" ></Chart>
</template>

<script>
import Chart from "primevue/chart";

export default {
  beforeCreate() {
    Chart.destroy;
  },
  props: {
    title: String,
    sluScores: Array
  },
  data() {
    return {
      percentColors: [
        { pct: 0.0, color: { r: 0x00, g: 0x94, b: 0x06 } },
        { pct: 0.5, color: { r: 0xfa, g: 0xb7, b: 0x33 } },
        { pct: 1.0, color: { r: 0xc2, g: 0x0f, b: 0x01 } } ]
    }
  },
  methods: {
    getColorForPercentage(pct) {
      for (var i = 1; i < this.percentColors.length - 1; i++) {
        if (pct < this.percentColors[i].pct) {
          break;
        }
      }
      var lower = this.percentColors[i - 1];
      var upper = this.percentColors[i];
      var range = upper.pct - lower.pct;
      var rangePct = (pct - lower.pct) / range;
      var pctLower = 1 - rangePct;
      var pctUpper = rangePct;
      var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
      };
      return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    },
  },
  computed: {
    series(){
      if (!this.hypoTicks || !this.hypoDurations ||
        this.hypoTicks.length === 0 || this.hypoDurations.length === 0) return []
      let result = [this.hypoDurations.filter(duration => duration <= this.hypoTicks[0]).length]
      for(let i=0; i < this.hypoTicks.length - 1; i++) {
          result.push(
              this.hypoDurations.filter(
                  duration => duration > this.hypoTicks[i] &&
                  duration <= this.hypoTicks[i+1]).length)
        }
      result.push(this.hypoDurations.filter(duration => duration > this.hypoTicks[this.hypoTicks.length-1]).length)
      return result
    },
    options() {
      return {
        plugins: {
          legend: {
            position: 'right',
          }
        },
        title: {
          text: this.title,
          style: {
            fontSize:  '16px',
          }
        },
        tooltip: {
          y: {
            formatter: function(value) {
              return value + " cases";
            }
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: 'Total Cases',
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: false
          }
        }
      }
    },

    chartData() {
      var data = new Array(30).fill(0)
      this.sluScores.forEach(score => {
        if (score < 1)
          data[0] += 1
        if (score >=1 && score <= 5)
          data[1] += 1
        if (score >= 6 && score <= 10)
          data[2] += 1
        if (score >= 11 && score <= 15)
          data[3] += 1
        if (score >= 16 && score <= 20)
          data[4] += 1
        if (score >= 21 && score <= 25)
          data[5] += 1
        if (score >= 26 && score <= 30)
          data[6] += 1
      })
      return {
        labels: ["0", "1-5", "6-10", "11-15", "16-20", "21-25","26-30"],
        datasets: [
          {
            data: data,
            backgroundColor: [
              'rgb(' + [42,151,12].join(',') + ')',
              'rgb(' + [73,124,10].join(',') + ')',
              'rgb(' + [90,108,9].join(',') + ')',
              'rgb(' + [117,84,8].join(',') + ')',
              'rgb(' + [128,73,7].join(',') + ')',
              'rgb(' + [148,55,5].join(',') + ')',
              'rgb(' + [192,16,1].join(',') + ')',
            ],
            borderColor: 'transparent',
            fill: true
          }
        ]
      }
    }
  },

};
</script>
